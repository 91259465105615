export const CLIENT_SEARCH_TEXT_CHANGED = "CLIENT_SEARCH_TEXT_CHANGED";
export const CLIENT_LOADED = "CLIENT_LOADED";
export const CLIENT_LOADING_FAILED = "CLIENT_LOADING_FAILED";
export const CLIENT_NOT_FOUND = "CLIENT_NOT_FOUND";
export const PERSON_INFO_LOADED = "PERSON_INFO_LOADED";
export const PERSON_MEP_TOKEN = "PERSON_MEP_TOKEN";
export const CLIENT_LOADING = "CLIENT_LOADING";
export const CLIENT_CLEARED = "CLIENT_CLEARED";
export const SET_CLIENT_TYPE = "SET_CLIENT_TYPE";
export const REPRESENTATIVES_LOADING = "REPRESENTATIVES_LOADING";
export const REPRESENTATIVES_LOADING_FAILED = "REPRESENTATIVES_LOADING_FAILED";
export const REPRESENTATIVES_LOADED = "REPRESENTATIVES_LOADED";
export const REPRESENTATIVES_CLEARED = "REPRESENTATIVES_CLEARED";
export const SET_REPRESENTATIVE = "SET_REPRESENTATIVE";

export const SET_COMPANY = "SET_COMPANY";
export const SET_PERSON = "SET_PERSON";
export const SET_AUTHORIZED_PERSON = "SET_AUTHORIZED_PERSON";


export const PRODUCT_DETAIL_LOADING = "PRODUCT_DETAIL_LOADING"; 
export const CLIENT_PRODUCTS_LOADING = "CLIENT_PRODUCTS_LOADING";
export const CLIENT_PRODUCTS_PAGE_LOADED = "CLIENT_PRODUCTS_PAGE_LOADED";

export const CLIENT_PRODUCTS_LOADING_FAILED = "CLIENT_PRODUCTS_LOADING_FAILED";
export const CLIENT_PRODUCTS_CLEARED = "CLIENT_PRODUCTS_CLEARED";

export const ACTIVE_PRODUCTS_LOADING = "ACTIVE_PRODUCTS_LOADING";
export const ACTIVE_PRODUCTS_LOADING_FAILED = "ACTIVE_PRODUCTS_LOADING_FAILED";
export const HISTORY_PRODUCTS_LOADING = "HISTORY_PRODUCTS_LOADING";
export const HISTORY_PRODUCTS_LOADING_FAILED = "HISTORY_PRODUCTS_LOADING_FAILED";
export const CARD_PRODUCTS_LOADING = "CARD_PRODUCTS_LOADING";
export const CARD_PRODUCTS_LOADING_FAILED = "CARD_PRODUCTS_LOADING_FAILED";
export const CLEAR_CARDS = "CLEAR_CARDS";
export const RESET_PRODUCTS = "RESET_PRODUCTS";

export const PRODUCT_FAILED = "PRODUCT_FAILED";
export const PRODUCT_CLEARED = "PRODUCT_CLEARED";
export const PRODUCT_TOGGLE_ACTIVE = "PRODUCT_TOGGLE_ACTIVE";
export const PRODUCT_SELECTED = "PRODUCT_SELECTED";
export const PRODUCT_RELATIONS_LOADING = "PRODUCT_RELATION_LOADING";
export const PRODUCT_RELATIONS_LOADING_FAILED = "PRODUCT_RELATIONS_LOADING_FAILED";
export const APPLY_CLIENT_TO_RELATION = "APPLY_CLIENT_TO_RELATION";

export const CODEBOOK_SET_RESULT = 'CODEBOOK_SET_RESULT';

export const CASETYPES_LOADING = "CASETYPES_LOADING";
export const CASETYPES_LOADED = "CASETYPES_LOADED";
export const CASETYPE_SELECTED = "CASETYPE_SELECTED";
export const CASETYPE_SEARCH_TEXT_CHANGED = "CASETYPE_SEARCH_TEXT_CHANGED";
export const CASETYPE_ONLY_FREQUENT_CHANGED = "CASETYPE_ONLY_FREQUENT_CHANGED";
export const CASETYPES_BY_EXPRESSION_LOADED = "CASETYPES_BY_EXPRESSION_LOADED";
export const CASETYPES_BY_EXPRESSION_FAILED = "CASETYPES_BY_EXPRESSION_FAILED";
export const CASETYPES_BY_EXPRESSION_LOADING = "CASETYPES_BY_EXPRESSION_LOADING";
export const CASETYPE_FREQUENCIES_LOADED = "CASETYPE_FREQUENCIES_LOADED";
export const CASETYPES_FAILED = "CASETYPES_FAILED";
export const CASETYPE_GTI_LINK_LOADED = "CASETYPE_GTI_LINK_LOADED";
export const CASETYPE_GTI_LINK_LOADING_FAILED = "CASETYPE_GTI_LINK_LOADING_FAILED";
export const CASETYPE_GTI_LINK_LOADING = "CASETYPE_GTI_LINK_LOADING";
export const CASE_POLLING_IN_PROGRESS = "CASE_POLLING_IN_PROGRESS";
export const CASE_POLLED = "CASE_POLLED";
export const CASE_POLL_FAILED = "CASE_POLL_FAILED";
export const CASE_POLL_TIMEDOUT = "CASE_POLL_TIMEDOUT";
export const CASE_POLL_SUCCESFUL = "CASE_POLL_SUCCESFUL";
export const STARTED_ASYNC_CASE = "STARTED_ASYNC_CASE";

export const CASETYPE_SET_FILTER = "CASETYPE_SET_FILTER";
export const CASETYPE_CLEAR_FILTER = "CASETYPE_CLEAR_FILTER";
export const CASETYPE_START = "CASETYPE_START";
export const CASETYPE_STARTING = "CASETYPE_STARTING";
export const CASETYPE_STARTED = "CASETYPE_STARTED";
export const CASETYPE_FAILED = "CASETYPE_FAILED";

export const VF_BE_NAME = 'backendProductName';
export const VF_NUMBER = 'number';
export const VF_ACTIVE = 'active';
export const VF_CLOSE_DATE = 'closeDate';
export const VF_BALANCE = 'balance';

export const PRODUCT_CODE = {
    "JPP_CTG_001": { name: 'Účet', icon: "money-xl", rank: 1, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_BALANCE]},
    "JPP_CTG_005": { name: 'Investice', icon: "money-xl", rank: 3, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_BALANCE]},
    "JPP_CTG_003": { name: 'Úvěr', icon: "money-xl", rank: 2, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_BALANCE]},
    "JPP_CTG_007": { name: 'Karta debetní', icon: "bankcard-xl", rank: 4, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE]},
    "JPP_CTG_008": { name: 'Karta kreditní', icon: "bankcard-xl", rank: 5, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE]},
    "JPP_CTG_004": { name: 'Pojištění', icon: "insurance-xl", rank: 6, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_CLOSE_DATE]},
    "JPP_CTG_002": { name: 'Spoření', icon: "savings-xl", rank: 7, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE]},
    "JPP_CTG_006": { name: 'Služby', icon: "offer-xl", rank: 8, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE]},
    "JPP_CTG_009": { name: 'Služba k produktu', icon: "offer-xl", rank: 9, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE]},
    "JPP_CTG_010": { name: 'Kontokorent', icon: "offer-xl", rank: 10, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_BALANCE, VF_CLOSE_DATE]},
    "JPP_CTG_011": { name: 'Investiční pozice', icon: "hand-coins-xl", rank: 11, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_BALANCE, VF_CLOSE_DATE]},
    "JPP_CTG_012": { name: 'Nefinanční produkt', icon: "offer-xl", rank: 12, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_BALANCE, VF_CLOSE_DATE]},
    "JPP_CTG_013": { name: 'Skupinové pojištění', icon: "insurance-xl", rank: 13, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_BALANCE, VF_CLOSE_DATE]},
    "JPP_CTG_014": { name: 'Služba k produktu - pojištění', icon: "insurance-xl", rank: 14, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_BALANCE, VF_CLOSE_DATE]},
    "JPP_CTG_015": { name: 'Benefity', icon: "plugin-giftcard-xl", rank: 14, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE]},
    "INACTIVE": { name: 'Neaktivní produkty', icon: "media-stop-xl", rank: 15, validFields:[VF_BE_NAME, VF_NUMBER, VF_ACTIVE, VF_BALANCE, VF_CLOSE_DATE]}
};

export const APPLICATION_CODE = {
    "CAR": {name: "Stížnosti", icon: "thumb-down-xl"},
    "UNO2": {name: "Servis", icon: "hand-coins"},
    "HER": {name: "Dědictví", icon: "template-xl"},
    "MNT": {name: "Správa hypoték", icon: "building-society-loan-xl"},
    "MOBILITA": {name: "Mobilita klientů", icon: "arrow-up-down-xl"},
    "ESIG2": {name: "Busy Banking", icon: "sms-code-xl"},
    "GDPR": {name: "GDPR", icon: "face-id-xl"},
    "DAM": {name: "Požadavky na dokumentaci", icon: "invoice-postal-xl\n"},
    "PASSBOOK": {name: "Vkladní knížky", icon: "pfm-taxes-xl"},
    "ACCOUNTS": {name: "Pasivní účty", icon: "savings-xl"}
};

export const FILTERS = {
    "complaint" : {label: "Stížnost/Reklamace", hashtags: ["stížnost"]},
    "payment" : {label: "Platba", hashtags: ["platba"]},
    "fee" : {label: "Poplatek", hashtags: ["poplatek"]},
    "documentation" : {label: "Dokumentace", hashtags: ["dokumentace"]},
    "extract" : {label: "Výpis", hashtags: ["výpis"]},
    "confirmation" : {label: "Potvrzení", hashtags: ["potvrzení"]},
    "busybanking" : {label: "Busybanking", hashtags: ["busy"]},
    "gdpr" : {label: "GDPR", hashtags: ["gdpr"]},
    "heritage" : {label: "Dědictví", hashtags: ["dědictví"]},
    "account" : {label: "Účet", hashtags: ["účet"]},
    "card" : {label: "Karta", hashtags: ["karta"]},
    "deposit-book" : {label: "Vkladní knížka", hashtags: ["vkladní", "knížka"]},
    "loan" : {label: "Úvěr", hashtags: ["úvěr"]},
    "mortgage" : {label: "Hypotéka", hashtags: ["hypotéka"]},
};

export const RELATION_OWNER = "42";

/**
 * SEARCH
 */
export const SEARCH_QUERY_CHANGED = "SEARCH_QUERY_CHANGED";
export const SEARCH_QUERY_ACTION = "SEARCH_QUERY_ACTION";
export const SEARCH_QUERY_ACTION_FAILED = "SEARCH_QUERY_ACTION_FAILED";
export const CLIENT_ENTITY_TYPES = [
    "PERSON",
    "COMPANY"
];

export const PRODUCT_ENTITY_TYPES = [
    "PRODUCT",
    "AGREEMENT"
];

export const PRODUCT_LOADING_TYPE = {
    ACTIVE: "ACTIVE",
    HISTORY: "HISTORY",
    CARD: "CARD"
};

/**
 * USER
 */
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USER_LOADING_FAILED = "USER_LOADING_FAILED";
export const USER_LOGOUT = "USER_LOGOUT";
export const TABLET_USER = "TABLET_USER";
export const TABLET_USER_CONFIRMED = "TABLET_USER_CONFIRMED";
export const PREFER_GTI = "PREFER_GTI";

/**
 * VIEWPORT
 */
export const VIEWPORT_UPDATED = "VIEWPORT_UPDATED";

/**
 * WATCH EVENTS
 */
export const STARTER_SELECTION_STARTED = "STARTER_SELECTION_STARTED";
export const STARTER_SEARCH_INPUT_EVENT = "STARTER_SEARCH_INPUT_EVENT";
export const STARTER_SEARCH_COMPLETE_EVENT = "STARTER_SEARCH_COMPLETE_EVENT";
export const STARTER_SEARCH_SELECTED_EVENT = "STARTER_SEARCH_SELECTED_EVENT";
export const STARTER_SEARCH_DELETED_EVENT = "STARTER_SEARCH_DELETED_EVENT";
export const STARTER_FILTER_INPUT_EVENT = "STARTER_FILTER_INPUT_EVENT";
export const STARTER_FILTER_HASHTAG_EVENT = "STARTER_FILTER_HASHTAG_EVENT";
export const STARTER_CASE_SELECTED = "STARTER_CASE_SELECTED";
export const STARTER_CASE_STARTED = "STARTER_CASE_STARTED";

export const ADFS = "https://idp.cs-test.cz/adfs/oauth2";
export const ADFS_AUTH_FAILED = "ADFS_AUTH_FAILED";
export const ADFS_ACCESS_EXPIRING = "ADFS_ACCESS_EXPIRING";
export const ADFS_ACCESS_EXPIRING_NOTED = "ADFS_ACCESS_EXPIRING_NOTED";
export const AUTH_INFO_LOADED = "AUTH_INFO_LOADED";
export const AUTH_INFO_FAILED = "AUTH_INFO_FAILED";
export const AUTH_INFO_LOADING = "AUTH_INFO_LOADING";
export const AUTH_INFO_INVALIDATE = "AUTH_INFO_INVALIDATE";
export const AUTH_REFRESHING = "AUTH_REFRESHING";
export const AUTH_REFRESHED = "AUTH_REFRESHED";
export const AUTH_REFRESH_FAILED = "AUTH_REFRESH_FAILED";

export const ALERT_HIDE = "ALERT_HIDE";
export const ALERT_SHOW = "ALERT_SHOW";
export const ALERT_FLOATING_CLOSE_DURATION = 10000;
export const ALERT_SUCCESS = "success";
export const ALERT_WARNING = "warning";
export const ALERT_DANGER = "danger";
export const ALERT_INFO = "info";

export const ICON_WARNING = "warning";
export const ICON_DANGER = "comment-important";
export const ICON_SUCCESS = "checkmark-circle";
export const ICON_INFO = "info";

export const PRESELECTED_NONE = "PRESELECTED_NONE";
export const PRESELECTED_CLUID = "PRESELECTED_CLUID";
export const PRESELECTED_CGPID = "PRESELECTED_CGPID";
export const PRESELECTED_BOTH = "PRESELECTED_BOTH";

export const REPORT_INCIDENT_STARTED = "REPORT_INCIDENT_STARTED";
export const REPORT_INCIDENT_FAILED = "REPORT_INCIDENT_FAILED";
export const REPORT_INCIDENT_FINISHED = "REPORT_INCIDENT_FINISHED";
export const REPORT_INCIDENT_CLEARED = "REPORT_INCIDENT_CLEARED";

export const ENVIRONMENT_PROPERTIES_ERROR = "ENVIRONMENT_PROPERTIES_ERROR";
export const ENVIRONMENT_PROPERTIES_LOADED = "ENVIRONMENT_PROPERTIES_LOADED";
export const ENVIRONMENT_PROPERTIES_LOADING = "ENVIRONMENT_PROPERTIES_LOADING";